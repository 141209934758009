import React, { useRef, useState } from "react";
import "./Contact.css";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BiMap } from "react-icons/bi";
import emailjs from "emailjs-com";
// import { useContext } from "react";
// import { ThemeContext } from "../../context.js";

export default function Contact(props) {
  const formRef = useRef();
  const [done, setDone] = useState(false);

  // const theme = useContext(ThemeContext);
  // const darkMode = theme.state.darkMode;

  function handleSubmit(event) {
    event.preventDefault();
    emailjs
      .sendForm(
        "service_t01w9fa",
        "template_ds9xwod",
        formRef.current,
        "RMuL9zUC5ng2-9npH"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className="contact-container">
      <div className="contact-bg"></div>
      <div className="contact-wrapper">
        <div className="contact-left">
          <h1 className="contact-title">Contact me</h1>
          <div className="contact-info">
            <div className="contact-info-item">
              <FaPhone />
              +1 240-344-2941
            </div>
            <div className="contact-info-item">
              <MdEmail />
              sergioxq12@gmail.com
            </div>
            <div className="contact-info-item">
              <BiMap />
              Emmitsburg, Maryland
            </div>
          </div>
        </div>
        <div className="contact-right">
          <p className="contact-description">
            <b>What is your story?</b> Get in touch. Connect. Bind with me.
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              style={{ backgroundColor: props.darkMode && "#333" }}
              type="text"
              className="contact-input"
              placeholder="Name"
              name="user_name"
            />
            <input
              style={{ backgroundColor: props.darkMode && "#333" }}
              type="text"
              className="contact-input"
              placeholder="Subject"
              name="user_subject"
            />
            <input
              style={{ backgroundColor: props.darkMode && "#333" }}
              type="email"
              className="contact-input"
              placeholder="Email"
              name="user_email"
            />
            <textarea
              style={{
                backgroundColor: props.darkMode && "#333",
                padding: "10px",
              }}
              rows="5"
              placeholder="Message"
              name="message"
            ></textarea>
            <button>Submit</button>
            {done && (
              <>
                <br />
                <h2 className="done-message">
                  Thank you for contacting with me!
                </h2>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
