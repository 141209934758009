import imdb1 from "./images/IMDb-site1.png";
import imdb2 from "./images/IMDb-site2.png";
import sortingVisualizer from "./images/Sorting-visualizer1.png";
import hashtagProject from "./images/HashtagProject.png";
import musique from "./images/Musique.png";
import todolist from "./images/TodoList.png";

const projects = [
  {
    id: 1,
    img: imdb1,
    link: "https://github.com/sergioq12/IMDb-API",
  },
  {
    id: 2,
    img: musique,
    link: "https://github.com/Kamoa17/CMSCI349-Song-Recommender",
  },
  {
    id: 3,
    img: hashtagProject,
    link: "https://github.com/sergioq12/Hashtag-Search",
  },
  {
    id: 4,
    img: imdb2,
    link: "https://github.com/sergioq12/IMDb-API",
  },
  {
    id: 5,
    img: sortingVisualizer,
    link: "https://github.com/sergioq12/Sorting-Algorithm-Visualizer",
  },
  {
    id: 6,
    img: todolist,
    link: "https://github.com/sergioq12/todo-list-react",
  },
];

export default projects;
