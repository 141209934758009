import "./App.css";

import { useState } from "react";
import ResumePage from "./components/ResumePage/ResumePage";
// import { useContext } from "react";
// import { ThemeContext } from "./context.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage/MainPage";

function App() {
  // const theme = useContext(ThemeContext);
  // const darkMode = theme.state.darkMode;
  const [darkMode, setDarkMode] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<MainPage darkMode={darkMode} setDarkMode={setDarkMode} />}
        />

        <Route path="/resume" element={<ResumePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
