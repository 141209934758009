import React from "react";
import "./About.css";
import programmingBackground from "../../images/programming-background.jpg";

export default function About() {
  return (
    <div className="about-container">
      <div className="about-left">
        <div className="about-card bg"></div>
        <div className="about-card">
          <img
            alt="programming"
            src={programmingBackground}
            className="about-img"
          />
        </div>
      </div>
      <div className="about-right">
        <h1 className="about-title">About Me</h1>
        <p className="about-subtitle">
          I am a Software Engineer with experience in Software Engineering and
          Backend development
        </p>
        <p className="about-description">
          I am from Colombia. I will be a future Software Engineer that enjoys
          figuring out solutions to daily problems. That probably explains why I
          am a Golfer and also want to be a Software Engineer. Having a new
          problem, very unique, is something allows me to enjoy my
          problem-solving skills.
        </p>
      </div>
      <div className=""></div>
    </div>
  );
}
