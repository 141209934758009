import React from "react";
import "./ProjectCard.css";

export default function ProjectCard(props) {
  return (
    <div className="card-container">
      <div className="card-browser">
        <div className="card-circle"></div>
        <div className="card-circle"></div>
        <div className="card-circle"></div>
      </div>
      <a href={props.link} target="_blank" rel="noreferrer">
        <img alt="project background" src={props.img} className="card-image" />
      </a>
    </div>
  );
}
