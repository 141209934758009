import React from "react";
import ProjectCard from "../ProjectCard/ProjectCard";
import "./ProductList.css";
import data from "../../data.js";

export default function ProductList() {
  const projectData = data.map((card) => {
    return <ProjectCard key={card.id} link={card.link} img={card.img} />;
  });

  return (
    <div className="pl-container">
      <div className="pl-intro">
        <h1 className="pl-intro-title">Bind & Connect</h1>
        <p className="pl-intro-description">
          Bind is a portfolio composed of the projects I have worked in since my
          experience as a growing Software Engineer.
        </p>
      </div>
      <div className="pl-list">{projectData}</div>
    </div>
  );
}
