import React from "react";
import "./Introduction.css";
import profilePicture from "../../images/profile-pic.png";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { HiOutlineDocumentText } from "react-icons/hi";

import { Outlet, Link } from "react-router-dom";

export default function Introduction() {
  return (
    <div className="intro-container">
      <div className="intro-left">
        <div className="intro-wrapper">
          <h2 className="intro-title-intro">Hello, My name is</h2>
          <h1 className="intro-name">Sergio Quijano</h1>
          <div className="intro-titles">
            <div className="intro-titles-wrapper">
              <div className="intro-title-item">Computer Scientist</div>
              <div className="intro-title-item">Software Engineer</div>
              <div className="intro-title-item">Mathematician</div>
              <div className="intro-title-item">Data Engineer</div>
              <div className="intro-title-item">Golfer</div>
              <div className="intro-title-item">Rising Senior</div>
            </div>
          </div>
          <p className="intro-description">
            I am a future Software Engineer and Data Engineer currently pursuing
            Bachelor of Sciences in Computer Science and Mathematics in Mount
            St. Mary's University in Maryland. I achieved a minor in Data
            Science. Below is my LinkedIn, GitHub, and my Resume for more
            details.
          </p>
          <div className="more-details-container">
            <AiFillLinkedin
              style={{
                fontSize: "65px",
                color: "#0072b1",
                marginRight: "40px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/in/sergio-quijano-s/";
              }}
            />

            <AiFillGithub
              style={{
                fontSize: "65px",
                color: "#171515",
                marginRight: "40px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://github.com/sergioq12";
              }}
            />

            <Link to="/resume">
              <HiOutlineDocumentText
                style={{ fontSize: "65px", color: "#171515" }}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="intro-right">
        <div className="profile-bg"></div>
        <div className="profile-image-container">
          <img alt="profile" src={profilePicture} className="profile-picture" />
        </div>
      </div>
    </div>
  );
}
