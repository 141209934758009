import React from "react";
import About from "../About/About";
import Introduction from "../Intro/Introduction";
import ProductList from "../ProductList/ProductList";
import Contact from "../Contact/Contact";
import Toggle from "../Toggle/Toggle";

function MainPage(props) {
  return (
    <div
      className="App"
      style={{
        backgroundColor: props.darkMode ? "#222" : "white",
        color: props.darkMode && "white",
      }}
    >
      <Toggle setDarkMode={props.setDarkMode} darkMode={props.darkMode} />
      <Introduction darkMode={props.darkMode} />
      <About darkMode={props.darkMode} />
      <ProductList darkMode={props.darkMode} />
      <Contact darkMode={props.darkMode} />
    </div>
  );
}

export default MainPage;
