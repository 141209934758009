import React from "react";
import "./Toggle.css";
import { BsFillSunFill } from "react-icons/bs";
import { BsFillMoonFill } from "react-icons/bs";
// import { useContext } from "react";
// import { ThemeContext } from "../../context";

export default function Toggle(props) {
  // const theme = useContext(ThemeContext);
  // const handleClick = () => {
  //   console.log("Here in HandleClick");
  //   theme.dispatch({ type: "TOGGLE" });
  // };
  const handleClick = () => {
    props.setDarkMode((prevState) => {
      return !prevState;
    });
  };
  return (
    <div className="toggle-container">
      <BsFillSunFill className="toggle-icon" style={{ color: "#333" }} />
      <BsFillMoonFill className="toggle-icon" style={{ color: "#333 " }} />
      <div
        className="toggle-button"
        onClick={handleClick}
        style={{ left: props.darkMode ? 0 : 25 }}
      ></div>
    </div>
  );
}
